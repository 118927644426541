import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import parse from "html-react-parser"
import Slide from "react-reveal/Slide";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Download from "../components/categories/Download"
import Documentation from "../components/categories/Documentation";

const CategoryPage = ({ data, location }) => {
  const { t, i18n } = useTranslation()

  const category = data.strapiCategory
  const products = data.allStrapiProduct.nodes

  const brand = (i18n.language === "tr" ? data.site?.siteMetadata?.titleTr : data.site?.siteMetadata?.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": category.title,
    "image": category.pictures[0]?.localFile.url,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "0",
      "availability": "https://schema.org/InStock",
      "url": data.site.siteMetadata.siteUrl + location.pathname,
      "priceValidUntil": ""
    },
    "description": category.features,
    "brand": brand
  }

  const seo = {
    title: category.metaTitle,
    description: category.metaDescription,
    keywords: category.metaKeywords,
    schema: schema
  }

  const image = getImage(category.pictures[0]?.localFile)

  return (
    <Layout className="bg-lightGray" navbarTheme="dark">
      <Seo {...seo} />

      <div className="bg-lightGray divide-y-[0.5px] divide-blue">
        <div>
          <div className="max-w-7xl mx-auto px-6 md:px-10 pb-28 lg:pb-32 pt-10">
            <div className="md:flex justify-between pb-10">
              <div>
                <h1 className="text-3xl md:text-5xl font-semibold text-darkBlue mb-6 max-w-4xl">
                  {category.title}
                </h1>
                <h3 className="text-darkBlue font-normal text-lg max-w-2xl">
                  {category.description}
                </h3>
              </div>
              <div className="mt-10 md:mt-4">
                <Download link={category.documentation[0]?.url} size={category.documentation[0]?.size} />
              </div>
            </div>

            <div className="overflow-hidden">
              <GatsbyImage
                image={image}
                alt={category.pictures[0]?.alternativeText}
                className="transition hover:scale-110"
              />
            </div>

            <div className="pt-10 md:pt-20">
              <Slide left><p className="text-xl font-bold text-blue">{t("features")}</p></Slide>
              <div className="pt-5 md:pt-10 md:flex justify-between">
                <div className="md:w-2/5">
                 <Slide bottom>
                  <p className="whitespace-pre-line text-lg text-darkBlue">{category.features}</p>
                 </Slide>
                </div>
                <div className="md:w-1/2 pt-24 md:pt-0">
                  {category.feature_list.map((feature, index) => {
                    const icon = getImage(feature.icon?.localFile)
                    return (
                      <Slide bottom duration={1000} delay={index*10}>
                        <div className="flex items-center mb-4" key={index}>
                          <div className="w-[2.5rem] h-[2.5rem]">
                            <GatsbyImage
                              image={icon}
                              alt={feature.icon?.alternativeText}
                              className="w-auto h-auto"
                              objectFit="contain"
                            />
                          </div>
                          <p className="ml-5 text-lg w-[16rem] lg:w-auto max-w-[16rem] md:max-w-none text-darkBlue">
                            {feature.title}
                          </p>
                        </div>
                      </Slide>
                    )})}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="max-w-7xl mx-auto px-6 md:px-10 pt-28 lg:pt-32 pb-16">
            <h2 className="text-3xl md:text-5xl font-semibold text-darkBlue">
              {t("typesTitle")}: {category.title}
            </h2>
            <div className="flex flex-wrap mt-10 md:mt-8 md:-mx-6 lg:-mx-10 box-border">
              {products.map(product => {
                const image = getImage(product.pictures[0]?.localFile)

                return (
                  <Link
                    key={product.id}
                    to={"/" + product.category.slug + "/" + product.slug}
                    className="block w-full md:w-1/3 mb-6 md:p-6 lg:p-10"
                  >
                    <div className="w-full">
                      <p className="text-darkBlue font-bold text-lg mb-5">
                        {product.title}
                      </p>
                      <div className="overflow-hidden">
                        <GatsbyImage
                          image={image}
                          alt={product.pictures[0]?.alternativeText}
                          className="transition hover:scale-110"
                        />
                      </div>
                      <p className="text-darkBlue text-lg mt-7">{product.description}</p>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div>
          <div className="max-w-7xl mx-auto px-6 md:px-10 py-28 lg:py-32">
            <div className="md:flex justify-between">
              <div className="md:w-2/5 py-3 md:py-0">
                <p className="text-blue text-xl font-bold mb-16">
                  {t("characteristics")}
                </p>
                <div>
                  <h4 className="text-darkGray font-bold text-lg">
                    {t("dimensions")}
                  </h4>
                  <div className="py-6 whitespace-pre-line italic font-light text-lg text-darkBlue">
                    {category.dimensions}
                  </div>
                </div>
                <div className="py-6">
                  <h4 className="text-darkGray font-bold text-lg">
                    {t("materials")}
                  </h4>
                  <div className="py-6 cms-content text-lg text-darkBlue">
                    {parse(category.materials.data?.childMarkdownRemark.html)}
                  </div>
                </div>
                <div className="py-6">
                  <h4 className="text-darkGray font-bold text-lg">
                    {t("usage")}
                  </h4>
                  <div className="usage-content py-6 text-lg !text-darkBlue">
                    {parse(category.usage.data?.childMarkdownRemark.html)}
                  </div>
                </div>
              </div>

              <div className="md:w-1/2 py-3 md:py-0">
                <p className="text-blue text-xl font-bold mb-3 md:mb-6">
                  {t("documentations")}
                </p>
                <p className="text-darkBlue text-lg mb-10 md:mb-16">{t("documentationDescription")}</p>
                <div className="">
                  <Documentation title={category.title} pdf={category.documentation[0].url} />
                  <Documentation title={t("generalDocument")} pdf={t("generalDocumentPdfLink")} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const pageQuery = graphql`
  query ($slug: String, $language: String!) {
    strapiCategory(slug: { eq: $slug }, locale: { eq: $language }) {
      id
      slug
      metaTitle
      metaDescription
      metaKeywords
      title
      description
      features
      dimensions
      documentation {
        alternativeText
        url
        size
      }
      materials {
        __typename
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
      pictures {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      usage {
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
      feature_list {
        title
        icon {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiProduct(
      filter: { category: { slug: { eq: $slug }, locale: { eq: $language } } }
    ) {
      nodes {
        id
        slug
        title
        description
        category{
          slug
          title
          locale
        }
        pictures {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.3)
            }
          }
        }
      }
    },
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`

export default CategoryPage
